<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Past Bookings</h1>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-craigtoun-venues-list-all' }"
                >
                  <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              Bookings
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="eventsTableHeaders"
              :items="events"
              no-data-text="No Events have been booked"
            >
              <template v-slot:item.date="{ item }">
                {{ item.formatted_dates.date }}
              </template>
              <template v-slot:item.payment_complete="{ item }">
                <v-chip
                  label
                  small
                  color="green white--text"
                  v-if="item.status == 'active'"
                  >Yes</v-chip
                >
                <v-chip label small color="red white--text" v-else>No</v-chip>
                <span v-if="item.status != 'active'">
                  {{ item.status ? item.status : "N/A" }}</span
                >
              </template>
              <template v-slot:item.venue="{ item }">
                {{ item.venue.name }}
              </template>
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-craigtoun-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  v-if="item.customer_id"
                  >{{ item.customer.full_name }}</router-link
                >
                <div v-else>{{ item.customer_name }}</div>
              </template>
              <template v-slot:item.has_responded="{ item }">
                <v-chip
                  label
                  small
                  color="green white--text"
                  v-if="item.has_responded"
                  >Yes</v-chip
                >
                <v-chip label small color="red white--text" v-else>No</v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="4"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      breadcrumbs: [
        {
          text: "Venue Hires",
          disabled: false,
          to: {
            name: "module-craigtoun-venues",
          },
          exact: true,
        },
        {
          text: "Venues",
          disabled: false,
          to: {
            name: "module-craigtoun-venues-list",
          },
          exact: true,
        },
        {
          text: "Venue",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      eventsTableHeaders: [
        { text: "Date", value: "date" },
        { text: "Venue", value: "venue" },
        { text: "Customer", value: "customer" },
        { text: "Total Attendees", value: "total_attendees" },
        { text: "Start Time", value: "start_time" },
        { text: "Craigtoun Responded?", value: "has_responded" },
        { text: "Payment Complete", value: "payment_complete" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteBooking: {
        dialog: false,
        booking: {},
        loading: false,
      },
    };
  },

  computed: {
    events() {
      let bookings = this.$store.getters["craigtoun/venuesStore/bookingsAll"];
      let date = new Date();

      return bookings.filter((item) => {
        const itemDate = new Date(item.date);
        if (date) {
          return date > itemDate;
        }

        return true;
      });
    },
  },
};
</script>
